@import 'public/styles/colors';

.MapsBanner {
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 20px auto;
  .icon-container-maps {
    margin-right: 10px;
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px solid map-get($color, normal);
    }
  }
  p {
    @each $color in $colors {
      &.#{map-get($color, color)} {
        color: map-get($color, normal);
      }
    }
  }
}
