$uam-blue: #0378cc;
$dark-blue: #154b6d;
$blue: #3ea49a;
$light-blue: #00aebc;
$dark-green: #29662c;
$green: #84bf41;
$green-uam: #32701e;
$dark-yellow: #edce3b;
$yellow: #efa92c;
$light-yellow: #fff1b0;
$red: #b5272d;
$orange: #e9822c;
$purple: #705f9a;
$brown: #5e4122;
$teal: #007483;
$dark-teal: #086c64;
$black: #1d252c;
$white: #ffffff;
$red-covid: #fd6563;
// Grays - Adri likes them
$light-gray: #f2f2f2;
$gray: #e0e0e0;
$dark-gray: #4f4f4f;

$colors: (
  //                         normal: normal/dark version         gradient: light version
  (
      color: 'uam-blue',
      normal: $uam-blue,
      gradient: $uam-blue
    ),
  (
    color: 'dark-blue',
    normal: $dark-blue,
    gradient: $dark-blue
  ),
  (
    color: 'blue',
    normal: $blue,
    gradient: $blue
  ),
  (
    color: 'light-blue',
    normal: $light-blue,
    gradient: $light-blue
  ),
  (
    color: 'dark-green',
    normal: $dark-green,
    gradient: $dark-green
  ),
  (
    color: 'green',
    normal: $green,
    gradient: $green
  ),
  (
    color: 'green-uam',
    normal: $green-uam,
    gradient: $green-uam
  ),
  (
    color: 'dark-yellow',
    normal: $dark-yellow,
    gradient: $dark-yellow
  ),
  (
    color: 'yellow',
    normal: $yellow,
    gradient: $yellow
  ),
  (
    color: 'red',
    normal: $red,
    gradient: $red
  ),
  (
    color: 'red-covid',
    normal: $red-covid,
    gradient: $red-covid
  ),
  (
    color: 'orange',
    normal: $orange,
    gradient: $orange
  ),
  (
    color: 'purple',
    normal: $purple,
    gradient: $purple
  ),
  (
    color: 'brown',
    normal: $brown,
    gradient: $brown
  ),
  (
    color: 'teal',
    normal: $teal,
    gradient: $teal
  ),
  (
    color: 'dark-teal',
    normal: $dark-teal,
    gradient: $dark-teal
  ),
  (
    color: 'light-gray',
    normal: $light-gray,
    gradient: $light-gray
  ),
  (
    color: 'gray',
    normal: $gray,
    gradient: $gray
  ),
  (
    color: 'dark-gray',
    normal: $dark-gray,
    gradient: $dark-gray
  ),
  (
    color: 'black',
    normal: $black,
    gradient: $black
  ),
  (
    color: 'white',
    normal: $white,
    gradient: $white
  )
);

@function color($color, $type: normal) {
  @return map-get(map-get($colors, $color), $type);
}
