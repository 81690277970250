@import 'public/styles/colors';

.MouseIcon {
  width: 40px;
  height: 70px;
  border: 3px solid $dark-gray;
  border-radius: 2000px;
  margin: 10px;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out forwards;
  .arrows-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 2px;
    animation: moving 3s ease-in-out infinite;
    .wheel {
      width: 3px;
      height: 15px;
      background-color: $dark-gray;
      border-radius: 2000px;
      margin: 2px 0;
    }
    .arrow {
      transform-origin: 50% 50%;
      &.top {
        transform: rotate(180deg) scale(0.5);
      }
      &.bottom {
        transform: scale(0.5);
      }
    }
  }
}

@keyframes moving {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
