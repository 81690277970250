.Modal,
.content {
  // Not desktop
  .kite-list-items {
    box-sizing: border-box;
    padding: 20px;
  }
}
.AfterGrade {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      right: 10%;
      width: 60%;
      bottom: 35%;
    }
    .logo-main {
      position: absolute;
      width: 90%;
      left: 5%;
      bottom: 35%;
      .illustration {
        position: absolute;
        left: 10%;
        bottom: 0;
        width: 80%;
      }
      .image {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
    .logo-front {
      position: absolute;
      left: 10%;
      width: 80%;
      bottom: 20%;
    }
  }
}
