@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Modal,
.content {
  .desplegable-container {
    .romb-container {
      display: flex;
      justify-content: center;
      margin: 24px 0 10px;
    }
  }
  .box {
    margin: 20px 0;
    .quotation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .before {
        margin-right: 10px;
        transform-origin: center center;
        @include transform(rotate(180deg));
      }
      .after {
        margin-left: 10px;
      }
    }
    &.fat {
      border-width: 3px;
      p {
        color: $dark-gray;
      }
    }
  }
}
.Resources {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      left: 15%;
      width: 70%;
      bottom: 40%;
    }
    .logo-main {
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 45%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
    .logo-front {
      position: absolute;
      left: 15%;
      width: 70%;
      bottom: 35%;
    }
  }
}

.youth-container,
.enterpreneur-container,
.posgrade,
.new-system-list {
  box-sizing: border-box;
  padding-left: 20px;
  h4 {
    @each $color in $colors {
      &.#{map-get($color, color)} {
        color: map-get($color, normal);
      }
    }
  }
  .youth-block,
  .enterpreneur-block,
  .item-block {
    margin-top: 40px;
    h5 {
      margin: 0;
    }
    .icon-container {
      display: flex;
      justify-content: center;
    }
    @each $color in $colors {
      &.#{map-get($color, color)} {
        h5 {
          color: map-get($color, normal);
        }
      }
    }
  }
}

.separator-programs {
  height: 1px;
  width: 100%;
  margin: 40px 0 20px;
  @each $color in $colors {
    &.#{map-get($color, color)} {
      background-color: map-get($color, normal);
    }
  }
}

.center-icon {
  display: flex;
  justify-content: center;
}
