@import 'public/styles/colors';

.KiteTitle {
  position: relative;
  p {
    color: $dark-gray;
    margin: 0;
    position: relative;
    z-index: 50;
  }
  .kite-container {
    position: absolute;
    top: -50%;
    left: -10px;
  }
}
