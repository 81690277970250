@import 'public/styles/colors';
@import 'public/styles/variables';
@import 'public/styles/css3-mixins';

.AssociationDetail {
  position: fixed;
  z-index: 21000;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
  max-height: 0;

  @media screen and (max-width: $break-mobile) {
    width: 100%;
    top: 0;
    left: 0;
  }

  @media screen and (min-width: ($break-mobile + 1)) {
    width: 70%;
    top: 15%;
    left: 15%;
    height: 70%;
  }

  &.open {
    max-height: 100vh;
  }

  &.closed {
    padding: 0;
  }

  .mobile-inner-container {
    box-sizing: border-box;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    justify-content: flex-start;
    height: calc(100vh - 25px);

    @each $color in $colors {
      &.#{map-get($color, color)} {
        border: 3px solid map-get($color, normal);

        .association-name {
          color: map-get($color, normal);
        }
      }
    }

    .header-modal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      background-color: white;
      top: -2px;
      z-index: 500;
      width: 100%;
      padding: 0 15px;
      margin-left: -15px;

      .delete-association-container {
        padding: 5px;

        .delete-association-button {
          width: 20px;
          cursor: pointer;
          height: 20px;
          border-radius: 2000px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;

          @each $color in $colors {
            &.#{map-get($color, color)} {
              background-color: map-get($color, normal);
            }
          }
        }
      }
    }

    .video-container {
      width: 100%;
      flex-shrink: 0;
      margin-top: 10px;
    }

    .contact-section {
      padding-bottom: 10px;

      .icons-container {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .contact-icon {
          cursor: pointer;
        }
      }
    }
  }

  .dektop-inner-container {
    box-sizing: border-box;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    justify-content: flex-start;
    height: 100%;

    @each $color in $colors {
      &.#{map-get($color, color)} {
        border: 3px solid map-get($color, normal);

        .association-name {
          color: map-get($color, normal);
        }
      }
    }

    .header-modal {
      display: flex;
      align-items: center;
      text-align: center;
      position: sticky;
      background-color: white;
      top: -2px;
      z-index: 500;
      width: 100%;
      padding: 0 15px;
      margin-left: -15px;

      .association-name {
        width: 100%;
        text-align: center;
        float: left;
      }

      .delete-association-container {
        padding: 5px;
        float: right;

        .delete-association-button {
          width: 20px;
          cursor: pointer;
          height: 20px;
          border-radius: 2000px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;

          @each $color in $colors {
            &.#{map-get($color, color)} {
              background-color: map-get($color, normal);
            }
          }
        }
      }
    }

    .content-association {
      display: flex;
      justify-content: space-between;

      .left-association {
        width: calc(50% - 10px);
        height: 50vh;

        .video-container {
          width: 100%;
          flex-shrink: 0;
          margin-top: 10px;
        }

        .splash-container {
          position: relative;
          width: 100%;
          flex-shrink: 0;
          margin-bottom: 20px;
          .logo-back {
            max-width: 15px;
            position: absolute;
            top: 0;
            left: 45%;
          }
          .logo-main {
            position: relative;
            margin: auto;
            max-width: 300px;
            .image {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              max-width: 150px;
            }
          }
        }

        .contact-section {
          padding-bottom: 10px;

          .icons-container {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .contact-icon {
              cursor: pointer;
            }
          }
        }
      }

      .right-association {
        width: calc(50% - 10px);
        height: 50vh;
        overflow: auto;
      }
    }
  }
}
