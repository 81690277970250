@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.CardMockup {
  border-radius: 15px;
  max-width: 300px;
  margin: auto;
  @include box-shadow(0, 5px, 15px, rgba(0, 0, 0, 0.15));
  .university-name {
    color: $dark-blue !important;
    padding: 5px;
    margin-bottom: 0;
  }
  .band-container {
    position: relative;
    .blue-band {
      position: absolute;
      top: 0;
      width: 90%;
      left: 0;
      right: 0;
      margin: auto;
      height: 24px;
      border: 1px solid $dark-blue;
    }
    .orange-band {
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      height: 18px;
      // background-color: $green;
      background-color: $green-uam;
    }
  }
  .dirt-container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px 0 15px;
    position: relative;
    z-index: 300;
    margin: 12px 0;
    .chip {
      margin-top: 20px;
    }
    .dirt {
      background-color: $light-gray;
      width: 52px;
      box-sizing: border-box;
      padding: 5px;
      img {
        width: 150%;
        margin-left: -25%;
        margin-bottom: -50%;
        margin-top: -20%;
      }
    }
  }
  .student-data {
    padding: 15px;
    box-sizing: border-box;
    p {
      margin: 0;
      color: $dark-blue !important;
    }
  }
}
