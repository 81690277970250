@import 'public/styles/colors';

.Modal,
.content {
  .sostenible-img {
    max-width: 200px;
    margin: auto;
  }
  .location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    border: 3px solid $orange;
    .pin {
      margin-right: 10px;
    }
  }
  .running-icon-container {
    display: flex;
    justify-content: center;
    margin: -30px 0;
  }
  .guided {
    .prices-container {
      p {
        margin: 0;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            margin-left: 10px;
            font-variant-numeric: tabular-nums;
          }
        }
      }
    }
    .activities-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .activity-box {
        border: 2px solid $orange;
        margin: 3px;
        padding: 5px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin: 0;
          text-align: center;
        }
        &.full {
          width: -webkit-calc(100% - 20px);
          width: -moz-calc(100% - 20px);
          width: calc(100% - 20px);
        }
        &.half {
          width: -webkit-calc(50% - 20px);
          width: -moz-calc(50% - 20px);
          width: calc(50% - 20px);
        }
      }
    }
  }

  .logos-container {
    display: flex;
    align-items: center;
    .right {
      flex-basis: 60%;
      .apple {
        box-sizing: border-box;
        padding: 0 10px;
      }
    }
  }
}
.LiveAutonoma {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      left: 25%;
      width: 5%;
      bottom: 50%;
    }
    .logo-main {
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 30%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        left: 10%;
        bottom: 0;
        width: 80%;
      }
    }
    .logo-front {
      position: absolute;
      left: 10%;
      width: 80%;
      bottom: 20%;
    }
  }
}
