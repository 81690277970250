@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Modal,
.content {
  .icon-pharmacy-container {
    display: flex;
    justify-content: center;
    .pharmacy-container {
      animation: scaling 5s ease-in-out infinite;
    }
  }
  .modern-mailbox {
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    margin: 20px 0;
    .icon-container {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    p {
      margin: 0;
    }
    @each $color in $colors {
      &.#{map-get($color, color)} {
        border: 2px solid map-get($color, normal);
      }
    }
  }
  .phising-upper-container {
    position: relative;
    .phising-container {
      position: absolute;
      top: 70%;
      right: 15px;
    }
  }
  .logo {
    margin: 15px auto;
    &.bankia {
      width: 30%;
    }
    &.santander {
      width: 70%;
    }
    &.correos {
      width: 40%;
    }
  }
}
.ManageDayByDay {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      right: 5%;
      width: 40%;
      bottom: 35%;
    }
    .logo-main {
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 35%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        left: 5%;
        bottom: 0;
        width: 95%;
      }
    }
    .logo-front {
      position: absolute;
      left: 10%;
      width: 20%;
      top: 10%;
    }
  }
}

@keyframes scaling {
  0% {
    @include transform(scale(1));
  }
  10% {
    @include transform(scale(1.1));
  }
  20% {
    @include transform(scale(1));
  }
  100% {
    @include transform(scale(1));
  }
}
