@import './colors';
@import './fonts';
@import './css3-mixins';

button,
.btn {
  font-size: $text-semi-normal;
  color: white;
  border: none;
  border-radius: 5px;
  min-width: 130px;
  padding: 10px;
  box-sizing: border-box;
  @include box-shadow(0, 3px, 0, 0, rgba($black, 0.15));
  @each $color in $colors {
    &.#{map-get($color, color)} {
      background-color: map-get($color, normal);
    }
  }
  &.disabled {
    pointer-events: none;
  }
}
