@import 'public/styles/colors';

.MailBox {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  .icon-container {
    margin-right: 10px;
    margin-bottom: -5px;
  }
  a {
    overflow-wrap: anywhere;
    margin: 0;
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px solid map-get($color, normal);
    }
  }
}
