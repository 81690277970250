@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.ArrowsDown {
  .arrow {
    transform-origin: center center;
    @include transform(rotate(-90deg));
    margin: -12px 0;
    animation: arrowFading 2s ease-in-out infinite;
  }
}

@keyframes arrowFading {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
