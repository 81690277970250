@import 'public/styles/colors';

.Modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  z-index: 20000;
  top: 0;
  left: 0;
}
