@import 'public/styles/colors';

.did-you-know {
  box-sizing: border-box;
  padding: 15px;
  position: relative;
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px dashed map-get($color, normal);
      .label {
        color: white !important;
        background-color: map-get($color, normal);
        position: absolute;
        top: -25px;
        left: 15px;
        padding: 5px;
      }
    }
  }
}
