@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.SocialCard {
  border: 3px solid;
  padding: 15px;
  margin: 10px 0;
  box-sizing: border-box;
  cursor: pointer;

  .title-container {
    div {
      font-size: small;
      line-height: normal;
      margin-bottom: 0px;
      transition: font-size 0.5s, margin 0.5s;

      &.open {
        font-size: large;
        margin-bottom: 15px;
      }
    }
  }

  .data-container {
    max-height: 0px;
    overflow: hidden;
    transition: opacity 0.8s, max-height 0.6s;
    opacity: 0;

    &.open {
      opacity: 1;
      max-height: 100vh;
    }

    .social-item {
      display: flex;
      align-items: center;
      margin: 5px 0;
      font-weight: bold;
      color: $dark-gray;

      .social-icon {
        margin-right: 10px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  @each $color in $colors {
    &.#{map-get($color, color)} {
      border-color: map-get($color, normal);
    }
  }
}
