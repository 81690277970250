@import 'public/styles/css3-mixins';
@import 'public/styles/variables';

.Kites {
  position: relative;
  width: 90%;
  margin: auto;
  .spacer-kites {
    height: 60vw;
    min-height: 60vw;
    max-height: 220px;
  }
  .kite {
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform-origin: center center;
    &.ceal {
      top: 30px;
      left: 30px;
    }
    &.sicue {
      top: 20px;
      right: 0;
    }
    &.semp {
      bottom: 0;
      left: 20px;
    }
    &.erasmus {
      bottom: 30px;
      right: 20px;
    }
  }
  &.hidden {
    .kite {
      opacity: 0;
      &.ceal {
        @include transform(translate(-15px, -15px) rotate(-20deg));
      }
      &.sicue {
        @include transform(translate(15px, -15px) rotate(45deg));
      }
      &.semp {
        @include transform(translate(-15px, 15px) rotate(-20deg));
      }
      &.erasmus {
        @include transform(translate(25px, 25px) rotate(45deg));
      }
    }
  }
  @media screen and (min-width: $break-tablet + 1) {
    .kite {
      &.ceal {
        top: 15%;
        left: 15%;
      }
      &.sicue {
        top: 10%;
        right: 10%;
      }
      &.semp {
        bottom: 5%;
        left: 20%;
      }
      &.erasmus {
        bottom: 18%;
        right: 20%;
      }
    }
    .spacer-kites {
      min-height: 220px;
    }
  }
}
