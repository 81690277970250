@import 'public/styles/colors';
@import 'public/styles/variables';
@import 'public/styles/css3-mixins';

.questions-container-list {
  padding: 20px 0;
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    h4 {
      margin: 0;
      width: 70%;
      color: $dark-gray;
      transition: all 0.3s ease-in-out;
    }
    .arrow-container-right {
      transform-origin: center center;
      @include transform(rotate(270deg));
      transition: transform 0.3s ease-in-out;
    }
    &.open {
      h4 {
        @include transform(scale(1.1) translateX(10px));
        @media screen and (min-width: $break-tablet + 1) {
          @include transform(scale(0.9) translateX(0));
        }
      }
      .arrow-container-right {
        @include transform(rotate(90deg));
      }
    }
    @each $color in $colors {
      &.#{map-get($color, color)} {
        h4 {
          color: map-get($color, normal);
        }
        hr {
          background-color: map-get($color, normal);
          height: 1px;
          border: none;
        }
      }
    }
  }
  .question-container {
    transition: all 0.7s ease-in-out;
    overflow: hidden;
    // border: 1px solid black;
    &.closed {
      max-height: 0px;
      opacity: 0;
    }
    &.open {
      max-height: 2500px;
      opacity: 1;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
