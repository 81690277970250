@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Menu {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  background-color: white;
  opacity: 0;
  @include transform(translateX(-100vw));
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding-bottom: 30%;
  &.open {
    opacity: 1;
    @include transform(translateX(0));
    .menu-container {
      .menu-list {
        li {
          animation: fadeIn 0.3s ease-in-out forwards;
        }
      }
    }
  }
  .menu-background {
    position: relative;
    display: flex;
    box-sizing: border-box;
    padding-right: 20px;
  }
  .menu-container {
    box-sizing: border-box;
    padding: 30px 30px 160px;
    white-space: nowrap;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow-wrap: break-word;
    .menu-list {
      overflow-wrap: break-word;
      li {
        display: flex;
        align-items: center;
        opacity: 0;
        cursor: pointer;
        overflow-wrap: break-word;
        p {
          color: $black;
          margin: 10px 0;
          overflow-wrap: break-word;
        }
        .kite-icon-container {
          position: relative;
          margin-right: 10px;
          flex-shrink: 0;
          .number-container {
            position: absolute;
            bottom: 45%;
            right: 10px;
            font-size: 24px;
            @include stroke(2, #fff);
            @each $color in $colors {
              &.#{map-get($color, color)} {
                color: map-get($color, normal);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    @include transform(translateX(0));
  }
  100% {
    opacity: 0;
    @include transform(translateX(-100vw));
  }
}
