@import 'public/styles/colors';

.back-container {
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 10px;
  max-height: 86px;
  background-color: white;
  border: 1px solid #fafafa;
  border-right: none;
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border-bottom: 20px solid map-get($color, normal);
    }
  }
  .back-button {
    display: flex;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
  .logo-container {
    max-width: 60px;
  }
  &.negative {
    .back-button {
      p {
        color: white !important;
      }
    }
    @each $color in $colors {
      &.#{map-get($color, color)} {
        background-color: map-get($color, normal);
      }
    }
  }
}
