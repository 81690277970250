@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.ContactCard {
  color: $dark-gray;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  .title-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
    .first-part {
      display: flex;
      align-items: baseline;
    }
    .arrow-container {
      transform-origin: center center;
      transition: transform 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      &.collapsed {
        @include transform(rotate(180deg));
      }
      &.not-collapsed {
        @include transform(rotate(270deg));
      }
    }
  }
  .data-container {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    &.hide {
      max-height: 0;
      opacity: 0;
    }
    &.show {
      max-height: 100%;
      opacity: 1;
    }
  }
  .item {
    margin: 10px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon-container {
      margin-right: 10px;
    }
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      .title-container {
        color: map-get($color, normal);
      }
    }
  }
}
