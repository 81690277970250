@import './css3-mixins';
@import './colors';
@import './variables';

html,
body {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: unset;
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

* {
  scroll-behavior: smooth;
}

.colored {
  @each $color in $colors {
    &.#{map-get($color, color)} {
      color: map-get($color, normal);
    }
  }
}

.kite-list {
  padding: 20px 0;
  .iconed {
    margin-bottom: 70px;
    position: relative;
    .icon-container {
      margin: auto;
      display: flex;
      justify-content: center;
    }
    h4 {
      margin: 0;
    }
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      .iconed {
        h4 {
          color: map-get($color, normal);
        }
      }
    }
  }
}

// RE DESIGN BABY ======== >>>> //
// Desktop
.main-page-desktop {
  box-sizing: border-box;
  padding: 80px 40px;
  position: relative;
  .arrow-circle-container {
    position: absolute;
    left: 0;
    top: 15%;
    z-index: 200;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .inner-block {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    .side {
      width: 50vh;
      flex-shrink: 0;
      height: 100%;
      &.left {
        box-sizing: border-box;
        padding: 5vh;
        h1 {
          margin: 0;
        }
        .left-side-desktop {
          .index-container-desktop {
            position: relative;
            margin: 20px auto;
            width: 90%;
            .inner-container {
              padding: 30px;
              box-sizing: border-box;
              position: relative;
              z-index: 100;
              background-color: white;
              // max-height: 50vh;
              overflow: auto;
              &::before {
                content: '';
                width: 2px;
                height: 50px;
                position: absolute;
                top: 0;
                left: 0;
                animation: moveIndexBar 3s ease-in-out infinite;
              }
              .index-list {
                li {
                  margin: 10px 0;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  cursor: pointer;
                  padding: 5px;
                  transition: border 0.3s ease-in-out;
                  .arrows-container {
                    margin-left: 30px;
                    .ArrowsDown {
                      display: flex;
                      .arrow {
                        transform-origin: center center;
                        @include transform(rotate(180deg) scale(0.5));
                        margin: -12px -10px;
                      }
                    }
                  }
                }
              }
            }
            .back-square {
              position: absolute;
              width: 65px;
              height: -webkit-calc(100% + 20px);
              height: -moz-calc(100% + 20px);
              height: calc(100% + 20px);
              top: -10px;
              left: -10px;
              @each $color in $colors {
                &.#{map-get($color, color)} {
                  background-color: map-get($color, normal);
                }
              }
            }
          }
        }
        .front-logo-container-desktop {
          position: absolute;
          @media screen and (max-height: 600px) {
            bottom: -10vh;
          }
          bottom: 0;
          width: 90%;
          margin: auto;
          left: 0;
          right: 0;
        }
      }
      &.right {
        transition: transform 0.3s ease-in-out;
        position: absolute;
        top: -15px;
        left: -15px;
        overflow: hidden;
        .content {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          .modern-modal-container {
            padding: 0 1.5vw;
            background-color: white;
            height: auto;
          }
        }
      }
    }
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      .inner-block {
        .side {
          &.left {
            h1,
            a {
              color: map-get($color, normal);
            }
            .left-side-desktop {
              .index-container-desktop {
                .inner-container {
                  &::before {
                    background-color: map-get($color, normal);
                  }
                  .index-list {
                    li {
                      &.selected {
                        border: 2px solid map-get($color, normal);
                      }
                    }
                  }
                }
              }
            }
          }
          &.right {
            border: 15px solid map-get($color, normal);
          }
        }
      }
      &.open {
        &::after {
          opacity: 1;
          background-color: map-get($color, normal);
        }
      }
    }
  }
  &.open {
    overflow: unset;
    flex-shrink: 0;
    width: 100vw;
    .arrow-circle-container {
      opacity: 1;
    }
    .inner-block {
      padding: 0 5%;
      box-sizing: border-box;
      .side {
        max-width: unset;
      }
      .left {
        width: 50%;
        .front-logo-container-desktop {
          position: absolute;
          bottom: -200vh;
        }
        .mock-log {
          width: 50%;
          margin: 50px auto;
        }
      }
      .right-container {
        height: 100vh;
        overflow: auto;
        // background-color: green;
        .spacer {
          height: 10%;
          min-height: 10%;
          max-height: 10%;
        }
        .right {
          width: -webkit-calc(100% - 45px);
          width: -moz-calc(100% - 45px);
          width: calc(100% - 45px);
          // @include transform(translateX(15px));
          margin-left: 15px;
          background-color: white;
          overflow: auto;
          height: auto;
          position: relative;
          .content {
            opacity: 1;
          }
        }
      }
    }
  }
  &.closed {
    .inner-block {
      cursor: pointer;
      .modern-modal-container {
        display: none;
      }
    }
  }
}

// Not desktop
.main-page {
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  position: relative;
  .inner-page {
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    @include css3-prefix(scroll-snap-type, x mandatory);
    display: flex;
    .side {
      position: relative;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      flex-shrink: 0;
      scroll-snap-align: start;
      &.left {
        padding: 20px;
        .header {
          display: flex;
          justify-content: flex-end;
          .logo-container {
            width: 12vh;
          }
        }
        .front-logo-container {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          overflow: hidden;
          .front-logo {
            width: 80%;
            margin: auto;
            position: relative;
            z-index: 50;
          }
        }
        .footer {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 10%;
          background-color: rgba(255, 255, 255, 0.8);
          .text-block {
            flex-basis: 60%;
            align-items: center;
            justify-content: space-between;
            h2 {
              @each $color in $colors {
                &.#{map-get($color, color)} {
                  color: map-get($color, normal);
                }
              }
            }
          }
          .arrows-container {
            height: auto;
            transform-origin: center center;
            @include transform(rotate(-90deg));
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            .arrow {
              height: auto;
            }
          }
        }
      }
      &.right {
        box-sizing: border-box;
        position: relative;
        // padding-top: 80px;
        h2 {
          margin: 0;
          padding-top: 20px;
          @each $color in $colors {
            &.#{map-get($color, color)} {
              color: map-get($color, normal);
            }
          }
        }
        .index-container {
          position: relative;
          width: 90%;
          margin: 20px auto;
          .title-container-inside {
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            h2 {
              width: -webkit-calc(100% - 65px);
              width: -moz-calc(100% - 65px);
              width: calc(100% - 65px);
            }
          }
          .inner-container {
            padding: 30px;
            box-sizing: border-box;
            position: relative;
            z-index: 100;
            background-color: white;
            max-height: 55vh;
            overflow: auto;
            &::before {
              content: '';
              width: 2px;
              height: 50px;
              position: absolute;
              top: 0;
              left: 0;
              animation: moveIndexBar 3s ease-in-out infinite;
            }
            .index-list {
              li {
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .arrows-container {
                  margin-left: 30px;
                  .ArrowsDown {
                    display: flex;
                    .arrow {
                      transform-origin: center center;
                      @include transform(rotate(180deg) scale(0.5));
                      margin: -12px -10px;
                    }
                  }
                }
              }
            }
          }
          .back-square {
            position: absolute;
            width: 65px;
            height: -webkit-calc(100% + 40px);
            height: -moz-calc(100% + 40px);
            height: calc(100% + 40px);
            top: -20px;
            left: -18px;
            @each $color in $colors {
              &.#{map-get($color, color)} {
                background-color: map-get($color, normal);
              }
            }
          }
        }
        .logo-mock {
          width: 50%;
          margin: auto;
        }
        .bottom-container {
          position: absolute;
          bottom: 40px;
          left: 15px;
          h2 {
            width: 60%;
          }
          .go-back {
            display: flex;
            align-items: center;
            cursor: pointer;
            .back-icon-container {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      .right {
        .index-container {
          .inner-container {
            &::before {
              background-color: map-get($color, normal);
            }
          }
        }
      }
    }
  }
}

.modern-modal-container {
  box-sizing: border-box;
  padding: 20px 20px 20px 0;
  height: 100vh;
  .inner-container {
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 20px 125px;
    overflow: auto;
    position: relative;
    .text-container {
      .actual-text {
        flex-grow: 1;
      }
    }
    @media screen and (max-width: $break-tablet) {
      .back-container {
        position: fixed;
        bottom: 20px;
        // left: 0;
        right: 20px;
        // margin: auto;
        width: -webkit-calc(100% - 20px);
        width: -moz-calc(100% - 20px);
        width: calc(100% - 20px);
        background-color: white;
      }
    }
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      background-color: map-get($color, normal);
      .inner-container {
        .text-container {
          h3 {
            color: map-get($color, normal);
          }
          .questions-container-list {
            hr {
              background-color: map-get($color, normal);
              height: 1px;
              border: none;
            }
          }
        }
      }
    }
  }
}

.desplegable-container {
  box-sizing: border-box;
  padding: 0 20px 20px;
}

.box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  position: relative;
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 1px solid map-get($color, normal);
    }
  }
  .icon-container {
    margin-right: 10px;
  }
  &.equal-padding {
    padding: 20px;
  }
  p {
    margin: 0;
  }
  .cursor-container {
    position: absolute;
    bottom: -20px;
    right: 20px;
  }
}

a {
  color: unset;
}

.animated-visibility {
  transition: all 0.3s ease-in-out;
  &.show-visible {
    opacity: 1;
    @include transform(translateY(0));
  }
  &.hidden-visible {
    opacity: 0;
    @include transform(translateY(10px));
  }
}

@keyframes moveIndexBar {
  0% {
    top: 0;
    height: 0;
    bottom: unset;
  }
  50% {
    height: 100%;
  }
  100% {
    top: unset;
    bottom: 0;
    height: 0;
  }
}
