@import 'public/styles/colors';
@import 'public/styles/fonts';
@import 'public/styles/css3-mixins';

.ListItem {
  position: relative;
  color: $dark-gray;
  font-size: $text-normal;
  padding: 15px 15px 15px 30px;
  margin: 20px 0;
  border-radius: 5px;
  @include box-shadow(3px, 3px, 0, 0, rgba($light-gray, 1));
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.with-link {
    cursor: pointer;
  }
  .external-link {
    margin-left: 10px;
  }
  .kite-container {
    position: absolute;
    left: -9px;
    top: -webkit-calc(50% - 10px);
    top: -moz-calc(50% - 10px);
    top: calc(50% - 10px);
  }
  &.visible {
    opacity: 1;
    @include transform(translateY(0));
  }
  &.hidden {
    opacity: 0;
    @include transform(translateY(10px));
  }
  &.custom {
    .kite-container {
      margin-right: 10px;
    }
    .children {
      padding-left: 15px;
    }
  }
  &:not(.custom) {
    box-shadow: none;
    border-radius: 0px;
    padding: 10px;
    margin: 15px 0;
    // border: 3px solid;
    // border-radius: 5px;
    @include box-shadow(-3px, 3px, 0, $light-gray);
    .kite-container {
      left: -25px;
    }
    p {
      margin: 0;
    }
    // @each $color in $colors {
    //     &.#{map-get($color, color)} {
    //         border-color: map-get($color, normal);
    //     }
    // }
  }
}
