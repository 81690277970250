@import 'public/styles/colors';

.StudiesCenter {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      right: 5%;
      bottom: 35%;
      width: 40%;
    }
    .logo-main {
      position: absolute;
      width: 60%;
      left: 20%;
      bottom: 35%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
      }
    }
    .logo-front {
      position: absolute;
      top: 15%;
      right: 10%;
      transform-origin: center center;
    }
  }
}

.structure-schema {
  .icon-container {
    display: flex;
    justify-content: center;
  }
  p {
    margin: 0;
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px solid map-get($color, normal);
      box-sizing: border-box;
      padding: 15px;
      h4 {
        color: map-get($color, normal);
        margin: 10px 0 0;
      }
      .government-council {
        border: 3px dashed map-get($color, normal);
        box-sizing: border-box;
        padding: 15px;
        margin-top: 20px;
        .inner-container {
          border: 3px solid map-get($color, normal);
          box-sizing: border-box;
          padding: 20px 10px;
          margin-top: 20px;
          .block {
            margin: 0 0 20px;
          }
        }
      }
    }
  }
}
