@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Associations {
  .group-title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px 0;
    .main-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      @include box-shadow(2px, 3px, 0, $light-gray);
      p {
        margin: 0 10px;
        @each $color in $colors {
          &.#{map-get($color, color)} {
            color: map-get($color, normal);
          }
        }
      }
      .arrow-container {
        transform-origin: center center;
        transition: transform 0.3s ease-in-out;
        &.closed {
          transform: rotate(270deg);
        }
        &.open {
          transform: rotate(90deg);
        }
      }
    }
  }
  .associations-list {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    &.open {
      max-height: 200vh;
    }
    &.closed {
      max-height: 0;
    }
    .association-card {
      margin: 15px 0;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      &.visible {
        opacity: 1;
      }
      &.hidden {
        opacity: 0;
      }
      p {
        margin: 0;
      }
      @each $color in $colors {
        &.#{map-get($color, color)} {
          border: 3px solid map-get($color, normal);
        }
      }
      .open-modal-icon {
        width: 20px;
        height: 20px;
        border-radius: 2000px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: white;
        @each $color in $colors {
          &.#{map-get($color, color)} {
            background-color: map-get($color, normal);
          }
        }
      }
    }
  }
}

.modal-content-association {
  height: 100vh;
  width: 100vw;
  background-color: yellow;
}
