@import 'public/styles/colors';
@import 'public/styles/variables';
@import 'public/styles/css3-mixins';

.loader {
  position: relative;
  width: 100vw;
  height: 100vh;

  .logo-container,
  .lottie-container {
    @include transform(translate(-50%, -50%));
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.Home {
  .logo-general-uam {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60%;

    @media screen and (min-width: $break-tablet + 1) {
      top: 100px;
      right: 130px;
      width: 200px;
    }
  }

  .menu-handler {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 30px;
    cursor: pointer;
    z-index: 1000;

    .bar {
      height: 4px;
      background-color: $black;
      margin: 4px 0;
      transform-origin: center center;
      transition: all 0.3s ease-in-out;
    }

    &.open {
      .bar {
        &.top {
          @include transform(translateY(8px) rotate(45deg));
        }

        &.medium {
          opacity: 0;
        }

        &.bottom {
          @include transform(translateY(-8px) rotate(135deg));
        }
      }
    }

    &.back {
      .bar {
        &.top {
          @include transform(translate3d(-10px, 12px, 0px) rotate(45deg) scaleX(0.5));
        }

        &.medium {
          @include transform(rotate(0deg));
        }

        &.bottom {
          @include transform(translate3d(-10px, -12px, 0px) rotate(135deg) scaleX(0.5));
        }
      }
    }

    @media screen and (max-width: $break-mobile) {
      &.white {
        .bar {
          background-color: white;
        }
      }
    }
  }

  #content-container {
    @media screen and (max-width: $break-tablet) {
      &.lock {
        & > div {
          overflow: hidden;
        }
      }
    }
    
    .horizontal-container {
      height: 100vh !important;
      width: 100vw !important;
      overflow-y: scroll;
      scroll-behavior: unset;
      @include css3-prefix(scroll-snap-type, y mandatory);
      -webkit-overflow-scrolling: touch;

      &.locked {
        @include css3-prefix(scroll-snap-type, unset);
        -webkit-overflow-scrolling: unset;
      }
    }

    .home-mobile,
    .main-page {
      height: 100vh;
      scroll-snap-align: start;
      -webkit-overflow-scrolling: touch;
    }

    .main-page-desktop {
      &.open {
        scroll-snap-align: center;
      }
    }

    .home-section {
      height: 100vh;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .logo-container {
        position: absolute;
        top: 15%;
        left: 25%;
        width: 50%;
        transition: transform 0.3s ease-in-out;
        z-index: 20000;
      }

      .title-container {
        position: absolute;
        left: 0%;
        width: 100%;
        bottom: 10%;
        box-sizing: border-box;
        padding: -webkit-calc(10% - 20px);
        padding: -moz-calc(10% - 20px);
        padding: calc(10% - 20px);

        h1 {
          color: $black;
          background-color: white;
          padding: 20px;
        }

        .square {
          position: absolute;
          bottom: 10px;
          left: -10px;
          z-index: -1;
        }

        @media screen and (min-width: $break-tablet + 1) {
          right: -webkit-calc(80px + 5%);
          right: -moz-calc(80px + 5%);
          right: calc(80px + 5%);
          left: auto;
          width: 30%;
          padding: 20px;

          .square {
            left: 0;
            bottom: 25px;
          }
        }
      }

      .arrows-down-container {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        @include transform(translateY(-150px));
      }

      &.home-desktop {
        box-sizing: border-box;
        padding: 10px 80px 80px 200px;
        background: none;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;

        .words-container {
          transform-origin: top left;
          @include transform(rotate(-90deg));
          position: absolute;
          left: 0;
          bottom: -80px;
          margin: 0;
          background-color: $gray;
          box-sizing: border-box;
          padding: 30px 50px 10px;
          width: 100vh;
          display: flex;
          justify-content: flex-end;
          p {
            font-size: 48px;
            margin: unset;
            color: $dark-gray;
          }

          img {
            height: 50px;
            width: unset;
          }
        }

        .logo-main-uam {
          position: absolute;
          bottom: 50px;
          left: 150px;
          width: 350px;
        }

        .mouse-icon {
          display: flex;
          position: absolute;
          bottom: 15px;
          right: 100px;
          color: $dark-gray;

          img {
            width: 70px;
          }

          p {
            font-size: 24px;
            width: 130px;
          }
        }

        .inner-container-home {
          width: 100%;
          height: 100%;
          flex-shrink: 0;
          border: 15px solid $dark-gray;
          background-image: url('../../../public/svg/home-background.svg');
          background-repeat: no-repeat;
          background-size: cover;
          background-position-y: 20%;

          .logo-container {
            position: relative;
            width: 40%;
          }

          .parallax-container {
            width: -webkit-calc(100% - 280px);
            width: -moz-calc(100% - 280px);
            width: calc(100% - 280px);
            height: -webkit-calc(100% - 160px);
            height: -moz-calc(100% - 160px);
            height: calc(100% - 160px);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;

            .title-container {
              width: 50%;
              height: fit-content;
              position: absolute;
              bottom: 0;
              left: 0;
            }

            &.logo {
              .logo-container {
                position: absolute;
                top: 10%;
                left: 50%;
              }
            }
          }
        }

        .inner {
          position: relative;
          height: 75vh;
          width: 100vh;

          .background {
            display: flex;
            justify-content: center;
            // .background-big-logo {
            // 	animation: grow 1s ease-in-out forwards;
            // 	.inner-logo {
            // 		position: absolute;
            // 		top: 25%;
            // 		left: 0;
            // 		right: 0;
            // 		margin: auto;
            // 		width: 50%;
            // 		transform: scale(0);
            // 		animation: grow 1s 1s ease-in-out forwards;
            // 	}
            // }
            div {
              position: absolute;
              left: 0;
            }
          }

          .sections {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            height: 100%;

            .splash-item {
              z-index: 2;
              color: transparent;
              cursor: pointer;
              animation: wingardiumLeviosa 30s ease-in-out infinite;
              &:hover {
                background-color: white;
                transition-duration: 0.2s;
                @include transform(scale(1.05));
                z-index: 3;
                border: 15px solid;

                .logo-title {
                  background-color: rgba(255, 255, 255, 0.9);
                  transition-duration: 0.2s;
                }
              }
              @for $i from 1 through 10 {
                &.item-#{$i} {
                  animation-delay: $i * 1.5s;
                }
              }
              &.UAMFirstSteps {
                position: relative;

                &:hover {
                  color: $orange;
                }

                .logo-back {
                  position: absolute;
                  bottom: 30%;
                  right: 0;
                  width: 40%;
                }

                .logo-main {
                  position: absolute;
                  width: 70%;
                  left: 15%;
                  bottom: 35%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }
                }

                .logo-front {
                  position: absolute;
                  top: 15%;
                  left: 15%;
                }
              }

              &.DigitalEnvironment {
                position: relative;

                &:hover {
                  color: $dark-blue;
                }

                .logo-back {
                  position: absolute;
                  top: 20%;
                  left: 15%;
                  width: 30%;
                }

                .logo-main {
                  position: absolute;
                  width: 60%;
                  left: 20%;
                  bottom: 40%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 75%;
                  }
                }

                .logo-front {
                  position: absolute;
                  top: 30%;
                  right: 5%;
                  width: 25%;
                }
              }

              &.StudiesCenter {
                position: relative;

                &:hover {
                  color: $green;
                }

                .logo-back {
                  position: absolute;
                  right: 5%;
                  bottom: 35%;
                  width: 40%;
                }

                .logo-main {
                  position: absolute;
                  width: 60%;
                  left: 20%;
                  bottom: 35%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    width: 100%;
                  }
                }

                .logo-front {
                  position: absolute;
                  top: 15%;
                  right: 10%;
                  transform-origin: center center;
                }
              }

              &.WhoCanHelp {
                position: relative;

                &:hover {
                  color: $light-blue;
                }

                .logo-back {
                  position: absolute;
                  top: 10%;
                  right: 10%;
                }

                .logo-main {
                  position: absolute;
                  width: 80%;
                  left: 10%;
                  bottom: 35%;

                  .illustration {
                    position: absolute;
                    left: 10%;
                    bottom: 0;
                    width: 80%;
                  }

                  .image {
                    position: absolute;
                    left: 10px;
                    bottom: 0;
                    width: 100%;
                  }
                }

                .logo-front {
                  position: absolute;
                  bottom: 35%;
                  width: 40%;
                  right: 5%;
                }
              }

              &.Resources {
                position: relative;

                &:hover {
                  color: $red;
                }

                .logo-back {
                  position: absolute;
                  left: 15%;
                  width: 70%;
                  bottom: 40%;
                }

                .logo-main {
                  position: absolute;
                  width: 70%;
                  left: 15%;
                  bottom: 45%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }
                }

                .logo-front {
                  position: absolute;
                  left: 15%;
                  width: 70%;
                  bottom: 35%;
                }
              }

              &.LiveAutonoma {
                position: relative;

                &:hover {
                  color: $dark-green;
                }

                .logo-back {
                  position: absolute;
                  left: 25%;
                  width: 5%;
                  bottom: 50%;
                }

                .logo-main {
                  position: absolute;
                  width: 70%;
                  left: 15%;
                  bottom: 30%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    left: 10%;
                    bottom: 0;
                    width: 80%;
                  }
                }

                .logo-front {
                  position: absolute;
                  left: 10%;
                  width: 80%;
                  bottom: 20%;
                }
              }

              &.ManageDayByDay {
                position: relative;

                &:hover {
                  color: $purple;
                }

                .logo-back {
                  position: absolute;
                  right: 5%;
                  width: 40%;
                  bottom: 35%;
                }

                .logo-main {
                  position: absolute;
                  width: 70%;
                  left: 15%;
                  bottom: 35%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    left: 5%;
                    bottom: 0;
                    width: 95%;
                  }
                }

                .logo-front {
                  position: absolute;
                  left: 10%;
                  width: 20%;
                  top: 10%;
                }
              }

              &.ProfessionalProfile {
                position: relative;

                &:hover {
                  color: $dark-teal;
                }

                .logo-back {
                  position: absolute;
                  left: 20%;
                  width: 60%;
                  bottom: 35%;
                }
                .logo-main {
                  position: absolute;
                  width: 45%;
                  left: 15%;
                  bottom: 35%;
                  .illustration {
                    position: absolute;
                    left: 30%;
                    bottom: 55px;
                    width: 100%;
                  }
                  .image {
                    position: absolute;
                    left: 28%;
                    bottom: 0%;
                    width: 80%;
                  }
                }
                .logo-front {
                  position: absolute;
                  left: 25.2%;
                  width: 23%;
                  bottom: 32%;
                }
              }

              &.Norms {
                position: relative;

                &:hover {
                  color: $brown;
                }

                .logo-back {
                  position: absolute;
                  right: 10%;
                  width: 50%;
                  top: 30%;
                }

                .logo-main {
                  position: absolute;
                  width: 70%;
                  height: 100%;
                  left: 15%;
                  bottom: 20%;

                  .illustration {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }

                  .image {
                    position: absolute;
                    left: 12%;
                    bottom: 19%;
                    width: 80%;
                  }
                }

                .logo-front {
                  position: absolute;
                  left: 10%;
                  width: 80%;
                  bottom: 20%;
                }
              }

              &.AfterGrade {
                position: relative;

                &:hover {
                  color: $yellow;
                }

                .logo-back {
                  position: absolute;
                  right: 10%;
                  width: 60%;
                  bottom: 35%;
                }

                .logo-main {
                  position: absolute;
                  width: 90%;
                  left: 5%;
                  bottom: 35%;

                  .illustration {
                    position: absolute;
                    left: 10%;
                    bottom: 0;
                    width: 80%;
                  }

                  .image {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                  }
                }

                .logo-front {
                  position: absolute;
                  left: 10%;
                  width: 80%;
                  bottom: 20%;
                }
              }

              &.Utilities {
                position: relative;

                &:hover {
                  color: $teal;
                }

                .logo-back {
                  position: absolute;
                  right: 15%;
                  width: 20%;
                  top: 25%;
                }

                .logo-main {
                  position: absolute;
                  width: 80%;
                  left: 10%;
                  bottom: 25%;

                  .illustration {
                    position: absolute;
                    left: 10%;
                    bottom: 0;
                    width: 80%;
                  }

                  .image {
                    position: absolute;
                    left: 20%;
                    bottom: 20px;
                    width: 80%;
                  }
                }

                .logo-front {
                  position: absolute;
                  left: 18%;
                  width: 15%;
                  top: 10%;
                }
              }

              &.Covid {
                position: relative;

                &:hover {
                  color: $red-covid;
                }

                .logo-back {
                  position: absolute;
                  left: 20%;
                  width: 5%;
                  top: 40%;
                }

                .logo-main {
                  position: absolute;
                  width: 90%;
                  left: 5%;
                  bottom: 35%;

                  .illustration {
                    position: absolute;
                    left: 10%;
                    bottom: 0;
                    width: 80%;
                  }

                  .image {
                    position: absolute;
                    left: 0%;
                    bottom: 0;
                    width: 100%;
                  }
                }

                .logo-front {
                  position: absolute;
                  right: 20%;
                  width: 10%;
                  bottom: 30%;
                }
              }
            }

            .logo-uam {
              position: relative;
              margin-top: -30px;
              z-index: 1;
              .background-big-logo {
                position: absolute;
                margin: -65% -55%;
                .kite > svg {
                  transform: rotateZ(-4deg);
                  width: 60vh;
                  height: auto;
                }
                .inner-logo {
                  position: absolute;
                  top: 23%;
                  left: -5%;
                  width: 60%;
                  right: 0;
                  margin: auto;
                  transform: scale(0);
                  animation: grow 1s 1s ease-in-out forwards;
                  & > svg {
                    width: 35vh;
                    height: auto;
                    transform: rotateZ(-4deg);
                  }
                  .letter-container {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-70%, -40%) scale(1.5);
                  }
                }
              }
            }

            .logo-title {
              position: absolute;
              bottom: 0;
              width: 80%;
              padding: 10%;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &.block-scroll {
      overflow-y: hidden !important;
    }
  }

  &.desktop {
    #content-container {
      display: flex;
      overflow-y: hidden !important;
      height: 100vh;
      max-width: 100%;

      .horizontal-container {
        display: flex;
        overflow-y: hidden !important;
        position: relative;
        height: 100vh;
        max-width: 100%;

        .home-section {
          max-width: 1500px;
        }
      }
    }
  }

  // Splash
  // .front-logo-container-desktop,
  .front-logo-container-home {
    position: relative;
    width: 100%;
    height: 100%;

    .logo-back {
      position: absolute;
      bottom: 40%;
      left: 10%;
      width: 60%;
    }

    .logo-main {
      position: absolute;
      width: 60%;
      min-width: 180px;
      left: 15%;
      bottom: 40%;

      .bottom {
        width: 160px;
      }

      .mid {
        position: absolute;
        bottom: 20px;
        left: 20px;
        animation: breathe 5s ease-in-out infinite;
      }

      .top {
        position: absolute;
        bottom: 40px;
        left: 40px;
        animation: breathe 5s 0.5s ease-in-out infinite;
      }
    }

    .logo-front {
      position: absolute;
      bottom: 54%;
      left: 40%;
      width: 200px;
    }

    .cross {
      position: absolute;
      bottom: 30%;
      right: 8%;
      z-index: 30;
    }

    &.desktop {
      border: 15px solid $dark-gray;

      .background {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;

        .div1 {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 50%;
          background-color: $green;
        }

        .div2 {
          position: absolute;
          left: 50%;
          top: 50%;
          height: calc(67vh - 80px);
          width: calc(67vh - 80px);
          background-color: $green;
          transform-origin: center;
          transform: translate(-50%, -50%) rotateZ(45deg);
        }
      }

      .splash.main {
        width: 100%;
        height: 100%;
        position: relative;

        .logo-main {
          position: absolute;
          width: 60%;
          min-width: 180px;
          left: 30;
          bottom: 20vh;

          .bottom {
            width: 30vh;
          }

          .mid {
            left: 5vh;
            bottom: 5vh;
          }

          .top {
            left: 8vh;
            bottom: 8vh;
          }
        }

        .logo-back {
          width: 25vh;
          bottom: 20vh;
        }

        .logo-front {
          left: 33vh;
          bottom: 35vh;
          width: 35vh;
        }

        .cross {
          bottom: 20%;
        }
      }
    }
  }
}

.front-logo-container-desktop {
  height: 50vh;
}

@keyframes topRow {
  0% {
    @include transform(translateX(-30%));
  }

  100% {
    @include transform(translateX(30%));
  }
}

@keyframes bottomRow {
  0% {
    @include transform(translateX(30%));
  }

  100% {
    @include transform(translateX(-30%));
  }
}

@keyframes breathe {
  0% {
    @include transform(translate(0, 0));
  }

  50% {
    @include transform(translate(15px, -15px));
  }

  100% {
    @include transform(translate(0, 0));
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wingardiumLeviosa {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 0);
  }
  30% {
    transform: translate(10px, 5px);
  }
  40% {
    transform: translate(0, -5px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
