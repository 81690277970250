@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.LeftIndex {
  position: fixed;
  top: 25%;
  left: 0;
  width: 50px;
  color: white;
  z-index: 200;
  li {
    position: relative;
    margin: -15px 0;
    transition: transform 0.3s ease-in-out;
    @include transform(translateX(-20px));
    svg {
      path {
        transition: stroke 1s ease-in-out;
      }
    }
    .section-number {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      color: $black;
      z-index: 300;
      text-align: center;
      padding: 5px 10px 5px 0;
      transition: opacity 0.3s ease-in-out;
      &.show {
        opacity: 1;
      }
      &.hide {
        opacity: 0;
      }
    }
    &.big {
      @include transform(translateX(-2px) scale(2));
      margin-bottom: 10px;
    }
  }
}
