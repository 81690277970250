@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.AddressBookComp {
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  p {
    overflow-wrap: break-word;
  }
  .top {
    p {
      margin: 0;
    }
  }
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10%;
  }
  .bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    .item-container {
      padding: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon-container {
        transform-origin: center center;
        &.twinkle {
          animation: twinkle 0.5s ease-in-out 1;
        }
      }
    }
  }
  .emails-container {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    .email-box {
      display: flex;
      align-items: center;
      padding: 10px 0;
      opacity: 0;
    }
    &.open {
      .email-box {
        animation: fadeIn 0.3s ease-in-out forwards;
      }
    }
  }
  .icon-container {
    margin-right: 10px;
    margin-bottom: -5px;
  }
  a {
    overflow-wrap: anywhere;
    margin: 0;
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px solid map-get($color, normal);
      .top {
        p {
          color: map-get($color, normal);
        }
      }
      .middle {
        .address-string {
          color: map-get($color, normal);
        }
      }
      .bottom {
        .subtitle {
          color: map-get($color, normal);
          text-align: center;
        }
      }
    }
  }
}

@keyframes twinkle {
  0% {
    @include transform(rotate(0));
  }
  33% {
    @include transform(rotate(20deg));
  }
  66% {
    @include transform(rotate(-20deg));
  }
  100% {
    @include transform(rotate(0));
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
