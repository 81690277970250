@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.ExternalLink {
  position: relative;
  margin: 20px 0;
  display: flex;
  align-items: center;
  padding: 5px;
  .icon-container {
    margin-right: 10px;
    margin-bottom: -3px;
  }
  .cursor-container {
    position: absolute;
    top: 70%;
    right: 20px;
  }
  a {
    overflow-wrap: anywhere;
    @each $color in $colors {
      &.#{map-get($color, color)} {
        color: map-get($color, normal);
      }
    }
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px solid map-get($color, normal);
    }
  }
}
