@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Modal,
.content {
  // Desktop
  &.main-page-desktop {
    .mock-log {
      width: 150px;
      margin: auto;
      animation: spin 60s infinite linear;
    }
    &.open {
      .inner-block {
        .left {
          .front-logo-container-desktop {
            .waves-container {
              @include transform(translateY(100vh));
            }
            .front-logo {
              @include transform(translateY(150vh));
            }
            .behind {
              @include transform(translateY(30vh));
            }
          }
        }
      }
    }
  }
  // Not desktop
  .mail-box {
    background-color: $light-yellow;
    border: 5px solid $dark-yellow;
    box-sizing: border-box;
    padding: 5px;
    p {
      margin: 0;
      overflow-wrap: break-word;
    }
  }
  .how-obtain {
    .logo-container {
      margin: 15px auto;
      &.uam-app {
        width: 80px;
      }
      &.google-play {
        width: 160px;
      }
      &.apple-store {
        width: 142px;
      }
    }
  }
}
.UAMFirstSteps {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      bottom: 30%;
      right: 0;
      width: 40%;
    }
    .logo-main {
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 35%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
    .logo-front {
      position: absolute;
      top: 15%;
      left: 15%;
    }
  }
  .right {
    .logo-mock {
      animation: spin 60s infinite linear;
    }
  }
}

@keyframes spin {
  0% {
    @include transform(rotate(0));
  }
  100% {
    @include transform(rotate(360deg));
  }
}
