@import './variables';

@font-face {
  font-family: Urbana;
  font-display: auto;
  src: url(./fonts/urbana.ttf);
}

@font-face {
  font-family: Heroic;
  font-display: auto;
  src: url(./fonts/heroic-cond-vrg-web-ltd-md.ttf);
}

@font-face {
  font-family: Heroic-Italic;
  font-display: auto;
  src: url(./fonts/heroic-cond-vrg-web-ltd-md-obq.ttf);
}

@font-face {
  font-family: Heroic-Bold;
  font-display: auto;
  src: url(./fonts/heroic-cond-vrg-web-ltd-hvy.ttf);
}

@font-face {
  font-family: Heroic-Bold-Italic;
  font-display: auto;
  src: url(./fonts/heroic-cond-vrg-web-ltd-bd-obq.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

$urbana: Urbana;
$montserrat: 'Montserrat', sans-serif;
$heroic: Heroic;
$heroic-italic: Heroic-Italic;
$heroic-bold: Heroic-Bold;
$heroic-bold-italic: Heroic-Bold-Italic;

/* FONT SIZES */
$text-mega: 56px;
$text-header: 42px;
$text-title: 36px;
$text-medium: 28px;
$text-semi-normal: 18px;
$text-normal: 14px;
$text-small: 8px;

.text-mega {
  @media (max-height: 550px) {
    font-size: $text-header;
  }
  @media (min-height: 550px) {
    font-size: $text-mega;
  }
}

.text-header {
  @media (max-height: 550px) {
    font-size: $text-title;
  }
  @media (min-height: 550px) {
    font-size: $text-header;
  }
}

.text-title {
  @media (max-height: 550px) {
    font-size: $text-medium;
  }
  @media (min-height: 550px) {
    font-size: $text-title;
  }
}

.text-medium {
  @media (max-height: 550px) {
    font-size: $text-semi-normal;
  }
  @media (min-height: 550px) {
    font-size: $text-medium;
  }
}

.text-semi-normal {
  font-size: $text-semi-normal;
}

.text-normal {
  font-size: $text-normal;
}

.text-small {
  font-size: $text-small;
}

.text-subnormal {
  margin: 15px;
}

.nowrap {
  white-space: nowrap;
}

.urbana {
  font-family: $urbana;
  font-weight: 100;
}

.montserrat {
  font-family: $montserrat;
}

.heroic {
  font-family: $heroic;
  &.italic {
    font-family: $heroic-italic;
  }
  &.bold {
    font-family: $heroic-bold;
    &.italic {
      font-family: $heroic-bold-italic;
    }
  }
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

body,
p,
ul,
a {
  font-family: $montserrat;
}

h1,
h3,
a,
button,
.list-container > li,
.nosearch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1 {
  line-height: 90%;
  @media screen and (max-width: $break-mobile) {
    font-size: $text-title;
  }
  @media screen and (min-width: ( $break-tablet + 1)) and (max-width: $break-desktop) {
    @media (max-height: 550px) {
      font-size: $text-title;
    }
    @media (min-height: 550px) {
      font-size: $text-header;
    }
  }
  @media screen and (min-width: ($break-desktop + 1)) {
    @media (max-height: 550px) {
      font-size: $text-header;
    }
    @media (min-height: 550px) {
      font-size: $text-mega;
    }
  }
}
