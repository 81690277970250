@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.KiteIcon {
  display: flex;
  position: relative;
  .kite-container {
    transform-origin: center center;
    @include transform(rotate(190deg));
  }
  .icon-container {
    position: absolute;
    bottom: 10px;
    left: 20px;
    width: 30px;
  }
}
