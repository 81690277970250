@import 'public/styles/colors';

.Modal,
.content {
  .desplegable-container {
    .block {
      margin: 30px 0;
      .dotted {
        padding-left: 10px;
        position: relative;
        &::before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 2000px;
          background-color: $blue;
          position: absolute;
          top: 6px;
          left: 0;
        }
      }
    }
  }
}
.Utilities {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      right: 15%;
      width: 20%;
      top: 25%;
    }
    .logo-main {
      position: absolute;
      width: 80%;
      left: 10%;
      bottom: 25%;
      .illustration {
        position: absolute;
        left: 10%;
        bottom: 0;
        width: 80%;
      }
      .image {
        position: absolute;
        left: 20%;
        bottom: 20px;
        width: 80%;
      }
    }
    .logo-front {
      position: absolute;
      left: 18%;
      width: 15%;
      top: 10%;
    }
  }
}

.pdf-outer-container {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  .pdf-container {
    width: 95vw;
    overflow: auto;
    margin: auto;
    height: 65vh;
    border-radius: 5px;
    position: relative;
    &.desktop {
      overflow: hidden;
    }
  }
  .cover-layer {
    position: absolute;
    width: 95vw;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 40000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    p {
      color: white;
      position: absolute;
      top: 20%;
      left: 100px;
    }
    @each $color in $colors {
      &.#{map-get($color, color)} {
        background-color: rgba(map-get($color, normal), 0.5);
      }
    }
    &:hover {
      backdrop-filter: blur(4px);
      opacity: 1;
    }
  }
}

.full-screen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba($black, 0.5);
  backdrop-filter: blur(4px);
  animation: fadeIn 0.3s ease-in-out forwards 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 28px;
    cursor: pointer;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
