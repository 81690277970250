@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.DotsGrill {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 2000px;
  }
  @each $color in $colors {
    &.#{map-get($color, color)} {
      .dot {
        background-color: map-get($color, normal);
      }
    }
  }
  &.show {
    .dot {
      @include transform(scale(0));
      animation: scaling 0.5s ease-in-out forwards;
    }
  }
  &.hide {
    .dot {
      @include transform(scale(1));
      animation: scaleOut 0.5s ease-in-out forwards;
    }
  }
}

@keyframes scaling {
  0% {
    @include transform(scale(0));
  }
  50% {
    @include transform(scale(1.2));
  }
  100% {
    @include transform(scale(1));
  }
}

@keyframes scaleOut {
  0% {
    @include transform(scale(1));
  }
  50% {
    @include transform(scale(1.2));
  }
  100% {
    @include transform(scale(0));
  }
}
