@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Modal,
.content {
  // Not desktop
  .kite-list-container {
    box-sizing: border-box;
    padding: 20px;
  }
  .did-you-know {
    margin: 40px 0;
    .exclamation {
      font-size: 99px;
      position: absolute;
      top: 85%;
      right: 15px;
      color: $light-blue;
      @include transform(rotate(8.34deg));
      animation: shake 5s ease-in-out infinite;
    }
  }
  .box {
    border-width: 3px;
  }
  .what-means {
    .blocks-container {
      li {
        margin-bottom: 30px;
        transition: all 0.3s ease-in-out;
        &.visible {
          opacity: 1;
          @include transform(translateY(0));
        }
        &.hidden {
          opacity: 0;
          @include transform(translateY(10px));
        }
      }
    }
  }
}
.Norms {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      right: 10%;
      width: 50%;
      top: 30%;
    }
    .logo-main {
      position: absolute;
      width: 70%;
      height: 100%;
      left: 15%;
      bottom: 20%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        left: 12%;
        bottom: 19%;
        width: 80%;
      }
    }
    .logo-front {
      position: absolute;
      left: 10%;
      width: 80%;
      bottom: 20%;
    }
  }
}

@keyframes shake {
  0% {
    @include transform(rotate(0));
  }
  10% {
    @include transform(rotate(20deg));
  }
  20% {
    @include transform(rotate(-20deg));
  }
  30% {
    @include transform(rotate(0));
  }
  100% {
    @include transform(rotate(0));
  }
}
