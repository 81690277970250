@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Quiz {
  .cards-container {
    margin: 20px 0 100px;
    position: relative;
    .card {
      box-sizing: border-box;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      z-index: 100;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include background-gradient($blue, $light-blue);
      @include box-shadow(0, 2px, 0, 0, rgba($black, 0.15));
      p {
        color: white;
        text-align: center;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }
      &.current {
        &.moving {
          animation: first 0.5s ease-in-out;
          p {
            opacity: 0;
          }
        }
      }
    }
    .second {
      position: absolute;
      z-index: 50;
      bottom: -30px;
      left: 0;
      right: 0;
      margin: 0;
      @include transform(scale(0.8));
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.5s ease-in-out;
        background-color: rgba($black, 0.2);
      }
      &.moving {
        animation: second 0.5s ease-in-out;
        &::after {
          opacity: 0;
        }
      }
    }
    .third {
      position: absolute;
      bottom: -60px;
      left: 0;
      right: 0;
      margin: 0;
      z-index: 10;
      @include transform(scale(0.6));
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.2);
      }
      &.moving {
        animation: third 0.5s ease-in-out;
      }
    }
    .forth {
      position: absolute;
      bottom: -90px;
      left: 0;
      right: 0;
      margin: 0;
      z-index: 10;
      @include transform(scale(0));
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.2);
      }
      &.moving {
        animation: forth 0.5s ease-in-out;
      }
    }
  }
  .btns-container {
    display: flex;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
  }
  .box {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes first {
  0% {
    @include transform(translateY(0));
    opacity: 1;
  }
  100% {
    @include transform(translateY(-200px));
    opacity: 0;
  }
}

@keyframes second {
  0% {
    @include transform(translateY(0) scale(0.8));
  }
  100% {
    @include transform(translateY(-30px) scale(1));
  }
}

@keyframes third {
  0% {
    @include transform(translateY(0) scale(0.6));
  }
  100% {
    @include transform(translateY(-30px) scale(0.8));
  }
}

@keyframes forth {
  0% {
    @include transform(translateY(0) scale(0));
  }
  100% {
    @include transform(translateY(-30px) scale(0.6));
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
