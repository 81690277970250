@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Modal,
.content {
  .find-info {
    .boxes-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .box {
        border-width: 2px;
      }
      .arrow-container {
        margin: 10px auto;
        animation: arrow 2s ease-in-out infinite;
      }
    }
  }
  .contest-container > img {
    border: 5px solid $light-blue;
  }
}

.WhoCanHelp {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      top: 10%;
      right: 10%;
    }
    .logo-main {
      position: absolute;
      width: 80%;
      left: 10%;
      bottom: 35%;
      .illustration {
        position: absolute;
        left: 10%;
        bottom: 0;
        width: 80%;
      }
      .image {
        position: absolute;
        left: 10px;
        bottom: 0;
        width: 100%;
      }
    }
    .logo-front {
      position: absolute;
      bottom: 35%;
      width: 40%;
      right: 5%;
    }
  }
}

.your-voice {
  max-width: 216px;
  margin: auto;
}

.break-the-silence-image {
  width: 80%;
  max-width: 350px;
  margin: 10px auto;
}

.gender-equality-image {
  width: 70%;
  max-width: 250px;
  margin: 10px auto;
}

.danger {
  border-radius: 14px;
  background-color: $red;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  color: white;
  .danger-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Courier New', monospace;
    .danger-title-number {
      font-size: 45px;
      line-height: 45px;
      margin: 0;
      margin-top: 12px;
    }
    .danger-title-text {
      font-size: 8px;
      line-height: 8px;
      margin: 0;
    }
    .danger-stars {
      width: 45px;
      margin-top: 12px;
    }
  }
  .danger-text-container {
    .danger-bold {
      border-radius: 14px;
      background-color: white;
      color: $red;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@keyframes arrow {
  0% {
    opacity: 0;
    @include transform(translateY(-10px));
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    @include transform(translateY(10px));
  }
}
