@import 'public/styles/colors';
@import 'public/styles/variables';

.MapCard {
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  margin: 20px auto;
  cursor: pointer;

  .icon-container-maps {
    margin-right: 10px;
  }

  @each $color in $colors {
    &.#{map-get($color, color)} {
      border: 3px solid map-get($color, normal);
    }
  }

  p {
    @each $color in $colors {
      &.#{map-get($color, color)} {
        color: map-get($color, normal);
      }
    }
  }
}

.full-screen-container {
  .backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .close {
    @media screen and (max-width: $break-tablet) {
      color: black !important;
    }
  }

  .modal {
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
    border: 15px solid;
    max-height: 100vh;
    max-width: 100vw;
    overflow: auto;

    @media screen and (min-width: ($break-tablet + 1)) {
      max-height: 95vh;
    }

    @each $color in $colors {
      &.#{map-get($color, color)} {
        border-color: map-get($color, normal);
      }
    }

    .map {
      max-width: 100vw;
      max-height: 100vh;
      overflow: auto;
    }
  }
}

.MapCardNumber {
  border-radius: 24px;
  color: white;
  font-size: 14px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @each $color in $colors {
    &.#{map-get($color, color)} {
      background-color: map-get($color, normal);
    }
  }
}
