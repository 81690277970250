@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.Modal,
.content {
  .online-teaching-image {
    width: 75px;
    margin: auto;
  }
  .logos-container-apps {
    display: flex;
    justify-content: space-around;
    .right-logos {
      .img-container {
        max-width: 140px;
      }
    }
  }

  .wifi-image {
    width: 70%;
    margin: 15px auto;
  }
  .sigma-container {
    width: 150px;
    margin: auto;
  }
  .monitor {
    width: 130px;
    margin: auto;
  }
  .icon-group {
    display: flex;
    padding: 0 15px;
    align-items: center;
    margin: 15px 0;
    border-radius: 5px;
    @include box-shadow(3px, 3px, 0, 0, rgba($black, 0.15));
    .icon-container {
      margin-right: 10px;
    }
  }
  .how-to-access {
    .logos-container {
      display: flex;
      align-items: center;
      .right {
        flex-basis: 60%;
        .apple {
          box-sizing: border-box;
          padding: 0 10px;
        }
      }
    }
  }
}
.DigitalEnvironment {
  // Splash
  .front-logo-container-desktop,
  .front-logo-container {
    position: relative;
    .logo-back {
      position: absolute;
      top: 20%;
      left: 15%;
      width: 30%;
    }
    .logo-main {
      position: absolute;
      width: 60%;
      left: 20%;
      bottom: 40%;
      .illustration {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      .image {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 75%;
      }
    }
    .logo-front {
      position: absolute;
      top: 30%;
      right: 5%;
      width: 25%;
    }
  }
}

// Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
